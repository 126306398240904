<template>
  <div id="csv-import-form">
    <v-dialog v-model="csvImportDialog" width="600" persistent>
      <v-card>
        <v-card-title style="background-color: #1e88e5">
          <v-row>
            <v-col>
              <span class="text-h5 white--text">CSV Upload</span>
            </v-col>
            <v-col>
              <v-icon color="white"> mdi-file-upload-outline mdi-48px </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-progress-linear v-if="uploadProgress > 0" v-model="uploadProgress" />
        <v-card-text>
          <v-form ref="form" v-model="formValid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <customer-auto-complete />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="jobType"
                  label="Job Type"
                  :items="customerImportJobTypes"
                  item-text="name"
                  item-value="id"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row>Overwrite Fields</v-row>
            <v-row>
              <v-checkbox v-model="overwriteAll" class="pr-2" label="All" />
              <v-checkbox
                v-model="overwriteShipmentNotes"
                class="pr-2"
                label="Shipment Notes"
              />
              <v-checkbox
                v-model="overwriteCustomerPrice"
                class="pr-2"
                label="Customer Price"
              />
              <v-checkbox
                v-model="overwriteCarrierStartBuy"
                class="pr-2"
                label="Carrier Start Buy"
              />
              <v-checkbox
                v-model="overwriteCarrierMaxBuy"
                class="pr-2"
                label="Carrier Max Buy"
              />
              <v-checkbox
                v-model="overwriteCarrierPrice"
                class="pr-2"
                label="Carrier Price"
              />
              <v-checkbox
                v-model="overwriteLoadWeight"
                class="pr-2"
                label="Weight"
              />
              <v-checkbox
                v-model="overwriteLoadHeight"
                class="pr-2"
                label="Height"
              />
              <v-checkbox
                v-model="overwriteDescription"
                class="pr-2"
                label="Description"
              />
            </v-row>
            <v-row>
              <v-file-input
                v-model="fileInput"
                show-size
                counter
                hint="File must be a .csv format"
                persistent-hint
                label="File input"
                accept=".csv"
                required
                :rules="[rules.required]"
              />
            </v-row>
            <v-col class="text-right">
              <v-btn small text color="red" @click="cancelUpload()">
                Close
              </v-btn>
              <v-btn small text color="blue" @click="submitUpload()">
                Submit
              </v-btn>
            </v-col>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import validation from "@/utils/form-validation"
import CustomerAutoComplete from "@/views/pages/components/CustomerAutoComplete"

export default {
  name: "CsvImportForm",
  components: {
    CustomerAutoComplete,
  },
  data() {
    return {
      formValid: false,
      rules: validation.rules,
      fileInput: null,
      jobType: 1,
      overwriteAll: false,
      overwriteShipmentNotes: false,
      overwriteCarrierStartBuy: false,
      overwriteCarrierMaxBuy: false,
      overwriteCarrierPrice: false,
      overwriteCustomerPrice: false,
      overwriteLoadWeight: false,
      overwriteLoadHeight: false,
      overwriteDescription: false,
    }
  },
  computed: {
    ...mapGetters("generic", {
      customerId: "customerId",
    }),
    ...mapGetters("csvImport", {
      csvImportDialog: "csvImportDialog",
      uploadProgress: "uploadProgress",
      customerImportJobTypes: "customerImportJobTypes",
    }),
  },
  mounted() {
    this.$store.dispatch("csvImport/GET_CSV_IMPORT_JOB_TYPES")
  },
  methods: {
    cancelUpload() {
      this.fileInput = null
      this.$refs.form.reset()
      this.overwriteAll = false
      this.overwriteShipmentNotes = false
      this.overwriteCarrierStartBuy = false
      this.overwriteCarrierMaxBuy = false
      this.overwriteCarrierPrice = false
      this.overwriteCustomerPrice = false
      this.overwriteLoadWeight = false
      this.overwriteLoadHeight = false
      this.overwriteDescription = false
      this.$store.commit("csvImport/setCsvImportDialog", false)
    },
    submitUpload() {
      if (this.$refs.form.validate()) {
        const fmData = new FormData()
        fmData.append("file", this.fileInput)
        fmData.append("customer_company_id", this.customerId)
        fmData.append("job_type", this.jobType)
        fmData.append("overwrite_all", this.overwriteAll)
        fmData.append("overwrite_shipment_notes", this.overwriteShipmentNotes)
        fmData.append(
          "overwrite_carrier_start_buy",
          this.overwriteCarrierStartBuy
        )
        fmData.append("overwrite_carrier_max_buy", this.overwriteCarrierMaxBuy)
        fmData.append("overwrite_carrier_price", this.overwriteCarrierPrice)
        fmData.append("overwrite_customer_price", this.overwriteCustomerPrice)
        fmData.append("overwrite_load_weight", this.overwriteLoadWeight)
        fmData.append("overwrite_load_height", this.overwriteLoadHeight)
        fmData.append("overwrite_description", this.overwriteDescription)
        this.$store
          .dispatch("csvImport/POST_CSV_IMPORT", fmData)
          .then((response) => {
            this.$refs.form.reset()
            this.overwriteAll = false
            this.overwriteShipmentNotes = false
            this.overwriteCarrierStartBuy = false
            this.overwriteCarrierMaxBuy = false
            this.overwriteCarrierPrice = false
            this.overwriteCustomerPrice = false
            this.overwriteLoadWeight = false
            this.overwriteLoadHeight = false
            this.overwriteDescription = false
            this.$store.commit("csvImport/setCsvImportDialog", false)
          })
      }
    },
  },
}
</script>
