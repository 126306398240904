<template>
  <v-container id="csv-import" fluid>
    <v-row>
      <v-col md="4" offset-md="8" class="text-right">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="grey" v-bind="attrs" class="mr-3" v-on="on">
              CSV Templates
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, index) in csvTemplates" :key="index">
              <v-list-item-title>
                <a
                  style="text-decoration: none; color: black"
                  :href="item.link"
                  >{{ item.name }}</a
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn class="mr-0" color="blue" @click="openCsvImport()">
          Upload CSV
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :page-count="numberOfPages"
      :headers="headers"
      :items="csvImportJobs"
      class="elevation-1"
      :options.sync="options"
      :server-items-length="totalCsvImportJobs"
      :loading="loading"
    >
      <template v-slot:item.status="{ item }">
        <v-progress-circular
          v-if="item.status === 1"
          indeterminate
          size="20"
          color="grey"
          inline
        />
        <v-badge
          v-else
          offset-x="5"
          :color="statusBadgeColor(item.status)"
          dot
        />
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ formatDate(item.updated_at) }}
      </template>
      <template v-slot:item.processed_at="{ item }">
        {{ formatDate(item.processed_at) }}
      </template>
      <template v-slot:item.errored_at="{ item }">
        {{ formatDate(item.errored_at) }}
      </template>
      <template v-slot:item.error_message="props">
        <v-edit-dialog
          v-if="props.item.error_message"
          :return-value.sync="props.item.error_message"
          width="250px"
        >
          <v-icon v-if="props.item.error_message"> mdi-alert-octagon </v-icon>
          <template v-slot:input>
            <div class="pa-3" style="max-width: 250px">
              <v-row>
                <v-col cols="1">
                  <v-icon>mdi-alert-octagon</v-icon>
                </v-col>
                <v-col>
                  <h4>Error</h4>
                </v-col>
              </v-row>
              {{ props.item.error_message }}
            </div>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.file="{ item }">
        <v-col>
          <a :href="item.file"><v-icon>mdi-file-excel-outline</v-icon></a>
        </v-col>
      </template>
    </v-data-table>
    <csv-import-form />
  </v-container>
</template>

<script>
import api from "@/api/api"
import { mapGetters } from "vuex"
import moment from "moment"
import CsvImportForm from "@/views/pages/csv-import/CsvImportForm"

export default {
  name: "CsvImport",
  components: {
    CsvImportForm,
  },
  data() {
    return {
      page: 0,
      numberOfPages: 0,
      loading: false,
      options: {},
      errorDialog: {},
      headers: [
        { text: "Id", align: "center", sortable: false, value: "id" },
        {
          text: "Customer",
          align: "center",
          sortable: false,
          value: "customer_company.name",
        },
        {
          text: "Job Type",
          align: "center",
          sortable: false,
          value: "job_type_display",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Created",
          align: "center",
          sortable: false,
          value: "created_at",
        },
        {
          text: "Processed",
          align: "center",
          sortable: false,
          value: "processed_at",
        },
        {
          text: "Error Message",
          align: "center",
          sortable: false,
          value: "error_message",
        },
        {
          text: "File",
          align: "center",
          sortable: false,
          value: "file",
        },
      ],
      csvTemplates: [
        {
          name: "Available Loads",
          link: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSm_5fvRRibMqKwAMRYxlJnycie-fE3Cea1XEJ9VEL4vtKrggmx7FVxglIUGZvPjH2oxmDlrjfECOV2/pub?gid=1819701258&single=true&output=csv",
        },
        {
          name: "Tendered Loads",
          link: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSm_5fvRRibMqKwAMRYxlJnycie-fE3Cea1XEJ9VEL4vtKrggmx7FVxglIUGZvPjH2oxmDlrjfECOV2/pub?gid=1819701258&single=true&output=csv",
        },
        {
          name: "EDI Appointments",
          link: "https://docs.google.com/spreadsheets/d/e/2PACX-1vQU8jD-brHRZgv7JF0t7yiIlsA4jqKJIMEFMsi3PoBryWVmP5pMTzO7HoZHXLo2YbwEkvaJUKL76iF6/pub?gid=0&single=true&output=csv",
        },
      ],
    }
  },
  computed: {
    ...mapGetters("csvImport", {
      csvImportDialog: "csvImportDialog",
      csvImportJobs: "csvImportJobs",
      totalCsvImportJobs: "totalCsvImportJobs",
    }),
  },
  watch: {
    options: {
      handler() {
        this.readDataFromAPI()
      },
    },
    deep: true,
  },
  created() {
    const socket = new WebSocket(
      `${process.env.VUE_APP_WEBSOCKET_URL}/ws/csv-customer-import-jobs/`
    )
    socket.onmessage = (event) => {
      this.updatedCsvImportJobs = JSON.parse(event.data)
      this.$store.commit(
        "csvImport/setCsvImportJobs",
        this.updatedCsvImportJobs.data
      )
      this.$store.commit(
        "csvImport/setTotalCsvImportJobs",
        this.updatedCsvImportJobs.count
      )
    }
  },
  methods: {
    readDataFromAPI() {
      this.loading = true
      const { page, itemsPerPage } = this.options
      let pageNumber = itemsPerPage * page - itemsPerPage
      if (pageNumber < 0) {
        pageNumber = 0
      }
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/customer-import-jobs/api/load-csv-import/?limit=${itemsPerPage}&offset=${pageNumber}`
        )
        .then((response) => {
          this.loading = false
          this.$store.commit(
            "csvImport/setTotalCsvImportJobs",
            response.data.count
          )
          this.$store.commit(
            "csvImport/setCsvImportJobs",
            response.data.results
          )
          this.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
        })
    },
    openCsvImport() {
      this.$store.commit("csvImport/setCsvImportDialog", true)
    },
    formatDate(value) {
      if (value) {
        return moment(value).format("MM-DD-YYYY HH:MM:SS")
      }
    },
    statusBadgeColor(value) {
      if (value === 2) {
        return "green"
      } else if (value === 3) {
        return "yellow"
      } else if (value === 4) {
        return "red"
      } else {
        return "grey"
      }
    },
  },
}
</script>
